<template>
  <div class="content-404">
    <div ref="content" class="main-content">
      <h1 class="er-title mr-bottom-15 fs-2500">{{ title }}</h1>
      <span class="content-center">
        <img
          src="../assets/img/mobile-not-supported.svg"
          alt="image représentant un portable avec un point d'exclamation dedans"
        />
      </span>
      <h3 class="er-under-title mr-top-30">
        Accès smartphone en cours de développement
      </h3>
      <p class="er-p mr-top-30">
        Le service est accessible depuis une tablette ou un ordinateur.
      </p>
      <div class="content-center mr-top-30 mr-bottom-15">
        <router-link
          :to="{ name: 'Tableau de bord' }"
          title="Tableau de bord"
          class="fr-btn fr-btn--lg fr-btn--secondary mr-2"
          >Tableau de bord</router-link
        >
      </div>
      <div class="content-center mr-top-30 mr-bottom-50">
        <a
          href="#"
          class="fr-btn fr-btn--lg fr-btn--secondary mr-2"
          @click="precedent($event)"
          title="Page précédente"
          ><i class="ri-arrow-left-line mr-2"></i>Page précédente</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SkipLinkMixin from '../mixins/skip-link-mixin';

export default {
  name: 'Page ne supporte pas mobile',
  mixins: [SkipLinkMixin],
  data() {
    return {
      title: 'la page demandée ne supporte pas format mobile'
    };
  },
  computed: {
    ...mapGetters(['getRouteData'])
  },
  methods: {
    precedent(event) {
      if (event) {
        event.preventDefault();
      }
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    if (
      this.getRouteData &&
      this.getRouteData.data &&
      this.getRouteData.data.title
    ) {
      this.title = this.getRouteData.data.title;
    } else {
      console.error('title not found');
    }
  }
};
</script>
<style lang="scss">
.er-title {
  color: $base-color;
  font-family: Marianne;
  font-size: 32px;
  font-weight: bold;
}
.er-under-title {
  color: $font-color-base;
  font-family: Marianne;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}
.mr-bottom-15 {
  margin-bottom: 15px;
}
.mr-bottom-50 {
  margin-bottom: 50px;
}
.mr-top-30 {
  margin-top: 30px;
}
.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.er-p {
  font-family: Marianne;
  font-size: 16px;
  font-weight: normal;
}
</style>
